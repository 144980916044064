html,
body,
#root {
  margin: 0;
  height: 100vh;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.signInBackground {
  background-image: url('../../assets/images/bg.png');
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

.pagesBack {
  background-image: url("../images/pagesBack.png");
}

.text-hover-white:hover {
  color: white;
}

.text-hover-primary:hover {
  color: #9b9b9b;
}

.text-hover-primary:hover {
  color: #9b9b9b;
} 

.cursor-pointer {
  cursor: pointer;
}

.tox-promotion,
.tox-statusbar__branding {
  display: none;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  /* For Safari */
}


.rounded-bottom-6 {
  border-radius: 0rem 0rem 3rem 3rem;
}