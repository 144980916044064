section.section.profile::-webkit-scrollbar {
    display: none;
}

.profile .profile-card img {
    width: 100px;
    border: 6px solid #fff;
}

/* .profcardtop {
    background: url(https://i.imgur.com/O1VTOTb.png);
    height: 150px;
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    margin-bottom: -50px;
} */

.profile .profile-card h2 {
    font-size: 24px;
    font-weight: 700;
    color: #2c384e;
    margin: 10px 0 0 0;
}

.profile .profile-card h3 {
    font-size: 18px;
}

.profile .profile-card .social-links a {
    font-size: 20px;
    display: inline-block;
    color: rgba(1, 41, 112, 0.5);
    line-height: 0;
    margin-right: 10px;
    transition: 0.3s;
}

.profile .profile-card .social-links a:hover {
    color: #012970;
}

.profile .profile-overview .row {
    margin-bottom: 5px;
    font-size: 15px;
}

.profile .profile-overview .card-title {
    color: #ff0000;
    margin: 20px 0 10px 0;
    font-weight: 600;
}

.profile .profile-overview .label {
    font-weight: 600;
    color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit label {
    font-weight: 600;
    color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit img {
    max-width: 120px;
}

/* Bordered Tabs */

.nav-tabs-bordered {
    border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
    margin-bottom: -2px;
    border: none;
    color: #2c384e;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
    color: #4154f1;
}

.nav-tabs-bordered .nav-link.active {
    background-color: #fff;
    color: #4154f1;
    border-bottom: 2px solid #4154f1;
}

section.section.profile {
    overflow-x: hidden !important;
}

.settings-tabs li button {
    padding: 15px;
}